import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Box,
  CircularProgress,
  InputLabel,
  TextField
} from '@material-ui/core';

const MultipleSelect = ({
  adjectives,
  selectedAdjs,
  setSelectedAdjs,
}: {
  adjectives: Array<string>,
  selectedAdjs: Array<string>,
  setSelectedAdjs: (Array<string>) => void,
}): React.Node => {
  const MIN_SELECTIONS = 5;

  const onSelect = (_, adjective) => {
    setSelectedAdjs(adjective);
  };

  return (
    <Box position="relative" my={4}>
      <InputLabel>Select adjectives</InputLabel>
      <Box minWidth={124}>
        <Autocomplete
          filterSelectedOptions
          getOptionLabel={(option) => option}
          multiple
          onChange={onSelect}
          options={adjectives.sort()}
          renderInput={(params) => <TextField autoFocus {...params} />}
          value={selectedAdjs}
        />
      </Box>
      <Box bottom={12} right={60} position="absolute">
        <CircularProgress
          color={selectedAdjs.length >= MIN_SELECTIONS ? "primary" : "secondary"}
          size={12}
          value={Math.min(100, (selectedAdjs.length / MIN_SELECTIONS) * 100)}
          variant="determinate"
        />
      </Box>
    </Box>
  );
};

export default MultipleSelect;
