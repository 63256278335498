// @flow

import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import joinClasses from "../utils/join-classes";

const useStyles = makeStyles((theme) => ({
  adjButton: {
    backgroundColor: "#F0F0F0",
    border: "1px solid #D0D0D0",
    borderRadius: "32px",
    color: "#787878",
    fontWeight: "bold",
    textTransform: "capitalize",
    [theme.breakpoints.up("xs")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "50%",
    },
  },
  selectedAdjButton: {
    backgroundColor: "#787878",
    color: "#F0F0F0",
    "&:hover": {
      backgroundColor: "#A6A6A6",
      color: "#F0F0F0",
    },
  },
}));

type Props = {
  adjective: string,
  isSelected: boolean,
  onClick: (string, boolean) => void,
};

const CMAdjectiveButton = ({
  adjective,
  isSelected,
  onClick,
}: Props): React.Node => {
  const classes = useStyles();
  return (
    <Button
      selected={isSelected}
      onClick={onClick}
      size="large"
      className={joinClasses({
        [classes.adjButton]: true,
        [classes.selectedAdjButton]: isSelected,
      })}
    >
      {adjective}
    </Button>
  );
};

export default CMAdjectiveButton;
