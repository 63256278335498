import * as React from "react";
import { useCallback, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import joinClasses from "../utils/join-classes";

type Props = $ReadOnly<{
  onClick: () => void,
}>;

const useStyles = makeStyles(() => ({
  CMShowOtherWordsIcon: {
    display: "flex",
  },
  CMShowOtherWordsLoading: {
    animation: "rotation 1s infinite ease-in-out",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

const CMShowOtherWordsButton = ({ onClick }: Props): React.Node => {
  const { CMShowOtherWordsIcon, CMShowOtherWordsLoading } = useStyles();
  const [animateIcon, setAnimateIcon] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const fetchNewAdjectives = useCallback(async () => {
    try {
      setAnimateIcon(true);
      await onClick();
    } catch (err) {
      setDisabledButton(true);
      console.log("Error fetching new words.");
    } finally {
      setAnimateIcon(false);
    }
  }, [onClick]);

  return (
    <Button
      onClick={fetchNewAdjectives}
      disabled={disabledButton}
      startIcon={
        <div
          aria-label="Button refresh icon"
          className={joinClasses({
            [CMShowOtherWordsIcon]: true,
            [CMShowOtherWordsLoading]: animateIcon,
          })}
        >
          <CachedIcon />
        </div>
      }
    >
      Show Other Words
    </Button>
  );
};

export default CMShowOtherWordsButton;
