// @flow

import type { ListIconType } from "./list-item";

import * as React from "react";
import { useCallback } from "react";
import List from "@material-ui/core/List";
import CMListItem from "./list-item";

export type SiteAnalysisStep =
  | "scanning"
  | "analyzing"
  | "comparing"
  | "generating";
type StepType = { [SiteAnalysisStep]: string };
type StepKeysType = Array<SiteAnalysisStep>;

type Props = {
  currentStep: SiteAnalysisStep,
};

/**
 * The order of the steps is important! The steps must be in first to last order.
 */
const stepsLabels: StepType = {
  scanning: "Scanning website",
  analyzing: "Analyzing colors",
  comparing: "Comparing adjectives",
  generating: "Generating report",
};
const stepKeys: StepKeysType = Object.keys(stepsLabels);

/**
 * This component displays a specific list of site analysis steps. Given the current
 * step in the analysis process, the list is displayed as a series of check marks
 * for steps completed, a waiting icon for the current step, and unchecked icons for
 * steps not yet current or complete.
 */
const CMSiteAnalysisStepList = ({ currentStep }: Props): React.Node => {
  const currentStepIndex: number = stepKeys.indexOf(currentStep);
  const getIconType = useCallback(
    (stepIndex: number): ListIconType =>
      currentStepIndex === stepIndex
        ? "waiting"
        : stepIndex < currentStepIndex
        ? "checked"
        : "unchecked",
    [currentStepIndex],
  );

  return (
    <List dense disablePadding>
      {stepKeys.map((step: SiteAnalysisStep, index: number): React.Node => (
        <CMListItem
          key={step}
          iconType={getIconType(index)}
          primary={stepsLabels[step]}
          size="small"
        />
      ))}
    </List>
  );
};

export default CMSiteAnalysisStepList;
