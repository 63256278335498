// @flow

import * as React from "react";
import { useCallback } from "react";
import CMAdjectiveButton from "./adjective-button";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const MOBILE_COLUMNS = 2;
const TABLET_UP_COLUMNS = 3;

const useStyles = makeStyles((theme) => ({
  adjContainer: {
    margin: "auto",
    paddingTop: "4%",
    width: "90%",
  },
}));

const CMAdjectiveGrid = ({
  adjectives,
  selectedAdjs,
  setSelectedAdjs,
}: {
  adjectives: Array<string>,
  selectedAdjs: Array<string>,
  setSelectedAdjs: (Array<string>) => void,
}): React.Node => {
  const classes = useStyles();
  const theme = useTheme();
  const isTabletUpSize = useMediaQuery(theme.breakpoints.up("md"));
  const toggleSelected = useCallback(
    (adjective: string, isSelected: boolean): void => {
      setSelectedAdjs(
        isSelected
          ? selectedAdjs.filter((adj) => adj !== adjective)
          : [...selectedAdjs, adjective],
      );
    },
    [selectedAdjs, setSelectedAdjs],
  );

  return (
    <div className={classes.adjContainer}>
      <Grid container spacing={2}>
        {adjectives.map((adjective: string, index: number) => {
          // TODO: investigate if this can be included as part of a theme
          const numOfColumns = isTabletUpSize
            ? TABLET_UP_COLUMNS
            : MOBILE_COLUMNS;

          const rowNumberLargeScreen = Math.floor(index / numOfColumns);
          const shouldAlignFromContainerStart =
            !isTabletUpSize || rowNumberLargeScreen % 2 === 0;
          const isSelected = selectedAdjs.includes(adjective);
          return (
            <Grid
              container
              item
              justify={
                shouldAlignFromContainerStart ? "flex-start" : "flex-end"
              }
              key={index}
              sm={4}
              xs={6}
            >
              <CMAdjectiveButton
                adjective={adjective}
                isSelected={isSelected}
                onClick={() => toggleSelected(adjective, isSelected)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CMAdjectiveGrid;
