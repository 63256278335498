// @flow

import * as React from "react";
import { useContext, useCallback, useEffect, useState } from "react";
import { useSiteAnalysisStep } from "../utils/customHooks";
import { CMContext } from "../../provider";
import { makeStyles } from "@material-ui/core/styles";
import CMAdjectiveGrid from "../components/adjective-grid";
import CMListItem from "../components/list-item";
import { navigate } from "gatsby";
import List from "@material-ui/core/List";
import LocalStorage from "../LocalStorage";
import CMShowOtherWordsButton from "../components/show-other-words-button";
import CMAdjectiveSelect from "../components/adjective-select";
import GAEventLogger, {
  GAEventActions,
  GAEventCategories,
} from "../utils/google_analytics/GAEventLogger";
import ShuffleArray from '../utils/ShuffleArray';
import {
  CMApiCallJobData,
  getAdjectives,
  getCMApiCallJobData,
  useColorMotiveAPI,
} from "../utils/colormotive_api_calls";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import Head from '../components/head';

type Props = {
  path: string,
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginLeft: "auto",
  },
}));

const ProgressList = (): React.Node => (
  <CMListItem
    size="large"
    iconType="waiting"
    primary={
      <Typography component="h1" variant="h5">
        Choose 5 words that describe your website.
      </Typography>
    }
    secondaryText={
      <Typography variant="subtitle1" component="h2">
        We'll compare how well your selections match your current colors
      </Typography>
    }
  />
);

const Adjectives = ({ path }: Props): React.Node => {
  const { setSiteAnalysisStep } = useSiteAnalysisStep();
  const classes = useStyles();
  const { dispatch, state } = useContext(CMContext);
  const jobID = state.cm_api_info.job_id ?? LocalStorage.get();
  const [adjectives, setAdjectives] = useState<Array<string>>([]);
  const [gridAdjectives, setGridAdjectives] = useState<Array<string>>([]);
  const [selectedAdjs, setSelectedAdjs] = useState<Array<string>>([]);

  const ADJECTIVES_SHOWN = 12;

  useEffect(() => {
    getAdjectives().then(({ data: { adjs } }) => {
      setAdjectives(adjs);
      setGridAdjectives([...ShuffleArray(adjs).slice(0, ADJECTIVES_SHOWN)]);
    });
  }, []);

  useEffect(() => {
    adjectives.length > 0 && GAEventLogger.logEvent(
      GAEventCategories.ADJECTIVES,
      GAEventActions.DISPLAY,
      {
        adjectives: adjectives.join(', '),
      },
    );
  }, [adjectives]);

  useEffect(() => {
    GAEventLogger.logEvent(
      GAEventCategories.ADJECTIVES,
      GAEventActions.USER_INPUT,
      {
        adjectives: adjectives.join(', '),
      },
    );
  }, [selectedAdjs]);

  const postSelectedAdjs = () => {
    getCMApiCallJobData(jobID, CMApiCallJobData.ADJS, "post", {
      adjs: selectedAdjs,
    });
  };

  const submitAdjs = useCallback(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.SUBMIT_ADJS, "post", {
        adjs: selectedAdjs,
      }),
    [jobID, selectedAdjs],
  );

  const showOtherWords = () => {
    const existingAdjs = new Set([...selectedAdjs, ...gridAdjectives]);
    const newAdjectives = 
      ShuffleArray(adjectives).filter(adj => !existingAdjs.has(adj));
    setGridAdjectives([
      ...selectedAdjs, 
      ...newAdjectives.slice(0, ADJECTIVES_SHOWN - selectedAdjs.length)
    ]);
  }

  const { executeApiCall: submitAdjectives } = useColorMotiveAPI(
    submitAdjs,
    // run immediately
    false,
    // run on initial load
    false,
    (response) => {
      setSiteAnalysisStep("generating");
      navigate("/results");
    },
  );

  const handleSubmit = useCallback(
    (event: SyntheticEvent<HTMLFormElement>): void => {
      event.preventDefault();
      GAEventLogger.logEvent(
        GAEventCategories.ADJECTIVES,
        GAEventActions.BUTTON_CLICK,
        {
          button: "NEXT_BUTTON",
        },
      );
      submitAdjectives();
    },
    [submitAdjectives],
  );
  
  return (
    <>
      <Head />
      <List dense disablePadding>
        <ProgressList />
        {adjectives.length === 0 ? (
          <CircularProgress />
        ) : (
          <CMListItem
            size="large"
            iconType="none"
            primary={
              <>
                <CMAdjectiveSelect
                  adjectives={adjectives}
                  selectedAdjs={selectedAdjs}
                  setSelectedAdjs={setSelectedAdjs}
                />
                <CMAdjectiveGrid
                  adjectives={gridAdjectives}
                  selectedAdjs={selectedAdjs}
                  setSelectedAdjs={setSelectedAdjs}
                />
              </>
            }
          />
        )}
      </List>
      <Box component="form" mt={8} onSubmit={handleSubmit}>
        <Grid alignItems="center" container justify="flex-end">
          <Grid item lg={8} xs={6}>
            <CMShowOtherWordsButton onClick={showOtherWords} />
          </Grid>
          <Grid className={classes.gridItem} item lg={2} xs={3}>
            <Typography variant="subtitle1">
              {`${selectedAdjs.length}/5 selections`}
            </Typography>
          </Grid>
          <Grid className={classes.gridItem} item lg={2} xs={3}>
            <Button
              disabled={selectedAdjs.length < 5}
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Adjectives;
