// @flow

import type { SiteAnalysisStep } from "../components/site-analysis-step-list";

import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { CMContext } from "../../provider";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CMSiteAnalysisStepList from "../components/site-analysis-step-list";
import Modal from "@material-ui/core/Modal";

type CMModalProps = {
  description: React.Node,
  onComplete?: () => mixed,
  title: React.Node,
};

type CMModalReturnType = {
  CMModal: (CMModalProps) => React.Node,
  handleModalOpen: () => void,
};

type SiteAnalysisReturnType = {
  siteAnalysisStep: SiteAnalysisStep,
  SiteAnalysisSteps: () => React.Node,
  setSiteAnalysisStep: (SiteAnalysisStep) => void,
};

const useStyles = makeStyles((theme) => ({
  CMModalBody: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid black",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    left: "50%",
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "33.3%",
  },
}));

export const useCMModal = (): CMModalReturnType => {
  const { CMModalBody } = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  const CMModal = ({
    description,
    onComplete,
    title,
  }: CMModalProps): React.Node => (
    <Modal
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      onClose={() => {
        setIsOpen(false);
        onComplete && onComplete();
      }}
      open={isOpen}
    >
      <Box className={CMModalBody}>
        <div id="modal-title">{title}</div>
        <div id="modal-description">{description}</div>
      </Box>
    </Modal>
  );

  return {
    CMModal,
    handleModalOpen,
  };
};

export const useSiteAnalysisStep = (): SiteAnalysisReturnType => {
  const { dispatch, state } = useContext(CMContext);
  const { siteAnalysisStep } = state;
  const setSiteAnalysisStep = useCallback(
    (nextStep: SiteAnalysisStep): void => {
      dispatch({
        type: "setSiteStep",
        payload: {
          ...state,
          siteAnalysisStep: nextStep,
        },
      });
    },
    [dispatch, state],
  );

  return {
    siteAnalysisStep,
    SiteAnalysisSteps: (): React.Node => (
      <CMSiteAnalysisStepList currentStep={siteAnalysisStep} />
    ),
    setSiteAnalysisStep,
  };
};
