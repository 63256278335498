// @flow

import * as React from "react";
import { useCallback } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ScheduleIcon from "@material-ui/icons/Schedule";

export type ListIconType = "checked" | "unchecked" | "waiting" | "none";
type ListPrimaryColor = "inherit" | "primary" | "secondary";
type ListSize = "default" | "small" | "large";

type Props = {
  iconType: ListIconType,
  primaryColor?: ListPrimaryColor,
  // primary content appears beside the icon
  primary: React.Node,
  // secondaryText appears below the primary text
  secondaryText?: React.Node,
  size?: ListSize,
};

const CMListItem = ({
  iconType,
  primaryColor = "inherit",
  primary,
  secondaryText,
  size = "default",
}: Props): React.Node => {
  const getIcon = useCallback(
    (
      color: ListPrimaryColor,
      fontSize: ListSize,
      type: ListIconType,
    ): React.Node => {
      const props = { color, fontSize };
      switch (type) {
        case "checked":
          return <CheckCircleIcon {...props} />;
        case "unchecked":
          return <RadioButtonUncheckedIcon {...props} />;
        case "waiting":
          return <ScheduleIcon {...props} />;
        case "none":
        default:
          return null;
      }
    },
    [],
  );

  const getPrimaryTextVariant = useCallback((listSize: ListSize): string => {
    switch (listSize) {
      case "large":
        return "h4";
      case "small":
        return "body2";
      case "default":
      default:
        return "body1";
    }
  }, []);

  return (
    <ListItem>
      <ListItemIcon>{getIcon(primaryColor, size, iconType)}</ListItemIcon>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          color: primaryColor,
          variant: getPrimaryTextVariant(size),
        }}
        secondary={secondaryText ?? undefined}
      />
    </ListItem>
  );
};

export default CMListItem;
